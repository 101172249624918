/* EB Garamond 700 - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: normal;
	font-weight: 700;
	src: url('/public/fonts/ebgaramond/EBGaramond-Bold.eot'); /* IE9 Compat Modes */
	src: local('EB Garamond Bold'), local('EBGaramond-Bold'),
		 url('/public/fonts/ebgaramond/EBGaramond-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/public/fonts/ebgaramond/EBGaramond-Bold.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/public/fonts/ebgaramond/EBGaramond-Bold.woff') format('woff'), /* Modern Browsers */
		 url('/public/fonts/ebgaramond/EBGaramond-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/public/fonts/ebgaramond/EBGaramond-Bold.svg#EBGaramond') format('svg'); /* Legacy iOS */
}

/* Noto Sans Regular 400 - latin_greek_cyrillic */
@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('/public/fonts/notosans/NotoSans-Regular.eot'); /* IE9 Compat Modes */
	src: local('Noto Sans'), local('NotoSans'),
		 url('/public/fonts/notosans/NotoSans-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/public/fonts/notosans/NotoSans-Regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/public/fonts/notosans/NotoSans-Regular.woff') format('woff'), /* Modern Browsers */
		 url('/public/fonts/notosans/NotoSans-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/public/fonts/notosans/NotoSans-Regular.svg#NotoSans') format('svg'); /* Legacy iOS */
}

/* Noto Sans Bold 700 - latin_greek_cyrillic */
@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('/public/fonts/notosans/NotoSans-Bold.eot'); /* IE9 Compat Modes */
	src: local('Noto Sans Bold'), local('NotoSans-Bold'),
		 url('/public/fonts/notosans/NotoSans-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/public/fonts/notosans/NotoSans-Bold.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/public/fonts/notosans/NotoSans-Bold.woff') format('woff'), /* Modern Browsers */
		 url('/public/fonts/notosans/NotoSans-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/public/fonts/notosans/NotoSans-Bold.svg#NotoSans') format('svg'); /* Legacy iOS */
}

/* Roboto Regular 400 - latin_greek_cyrillic */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('/public/fonts/roboto/Roboto-Regular.eot'); /* IE9 Compat Modes */
	src: local('Roboto'), local('Roboto-Regular'),
		 url('/public/fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/public/fonts/roboto/Roboto-Regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/public/fonts/roboto/Roboto-Regular.woff') format('woff'), /* Modern Browsers */
		 url('/public/fonts/roboto/Roboto-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/public/fonts/roboto/Roboto-Regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* Roboto Regular 400 Italic - latin_greek_cyrillic */
@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url('/public/fonts/roboto/Roboto-RegularItalic.eot'); /* IE9 Compat Modes */
	src: local('Roboto Italic'), local('Roboto-Italic'),
		 url('/public/fonts/roboto/Roboto-RegularItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/public/fonts/roboto/Roboto-RegularItalic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/public/fonts/roboto/Roboto-RegularItalic.woff') format('woff'), /* Modern Browsers */
		 url('/public/fonts/roboto/Roboto-RegularItalic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/public/fonts/roboto/Roboto-RegularItalic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* Roboto Bold 700 - latin_greek_cyrillic */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('/public/fonts/roboto/Roboto-Bold.eot'); /* IE9 Compat Modes */
	src: local('Roboto Bold'), local('Roboto-Bold'),
		 url('/public/fonts/roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/public/fonts/roboto/Roboto-Bold.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/public/fonts/roboto/Roboto-Bold.woff') format('woff'), /* Modern Browsers */
		 url('/public/fonts/roboto/Roboto-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/public/fonts/roboto/Roboto-Bold.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* Roboto Bold 700 Italic - latin_greek_cyrillic */
@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url('/public/fonts/roboto/Roboto-BoldItalic.eot'); /* IE9 Compat Modes */
	src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
		 url('/public/fonts/roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/public/fonts/roboto/Roboto-BoldItalic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('/public/fonts/roboto/Roboto-BoldItalic.woff') format('woff'), /* Modern Browsers */
		 url('/public/fonts/roboto/Roboto-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/public/fonts/roboto/Roboto-BoldItalic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* Font Awesome 5 - Brands */
@font-face {
	font-family: "Font Awesome 5 Brands";
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url(/public/fonts/fontawesome/fa-brands-400.eot);
	src: url(/public/fonts/fontawesome/fa-brands-400.eot) format("embedded-opentype"),
		 url(/public/fonts/fontawesome/fa-brands-400.woff2) format("woff2"),
		 url(/public/fonts/fontawesome/fa-brands-400.woff) format("woff"),
		 url(/public/fonts/fontawesome/fa-brands-400.ttf) format("truetype"),
		 url(/public/fonts/fontawesome/fa-brands-400.svg) format("svg");
}

/* Font Awesome 5 - Regular */
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url(/public/fonts/fontawesome/fa-regular-400.eot);
	src: url(/public/fonts/fontawesome/fa-regular-400.eot) format("embedded-opentype"),
		 url(/public/fonts/fontawesome/fa-regular-400.woff2) format("woff2"),
		 url(/public/fonts/fontawesome/fa-regular-400.woff) format("woff"),
		 url(/public/fonts/fontawesome/fa-regular-400.ttf) format("truetype"),
		 url(/public/fonts/fontawesome/fa-regular-400.svg) format("svg");
}

/* Font Awesome 5 - Solid */
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url(/public/fonts/fontawesome/fa-solid-900.eot);
	src: url(/public/fonts/fontawesome/fa-solid-900.eot) format("embedded-opentype"),
		 url(/public/fonts/fontawesome/fa-solid-900.woff2) format("woff2"),
		 url(/public/fonts/fontawesome/fa-solid-900.woff) format("woff"),
		 url(/public/fonts/fontawesome/fa-solid-900.ttf) format("truetype"),
		 url(/public/fonts/fontawesome/fa-solid-900.svg) format("svg");
}

.fab {
	font-family: 'Font Awesome 5 Brands';
	font-weight: 400;
}

.far {
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
}

.fa,
.fas {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
}
