// Core Styles
#html {
	position: relative;
}

.modernizr-no-js .lazy
{
	display: none;
}

#body.noscroll {
	overflow: hidden;
}

#body {
	position: relative;
	min-height: 100vh;

	.alert {
		position: absolute;
		top: 0.5rem;
		left: 0.5rem;
		right: 0.5rem;
	}

	.modal {
		.far {
			opacity: 1;
		}

		.far.btn-close {
			background: none;
		}

		.row {
			margin-bottom: 1rem;
		}

		.row:last-child {
			margin-bottom: 0px;
		}
	}

	#easycontact-modal {
		#privacy-statement {
			text-align: justify;
		}
	}

	.gallery {
		.gallery-cell {
			position: relative;

			.gallery-thumbnail {
				img {
					width: 100%;
					height: auto;
				}
			}
		}

		.gallery-fullscreen-button {
			position: absolute;
			top: 0.5rem;
			right: 1.5rem;
			z-index: 2;
			display: inline-block;
			width: 24px;
			height: 24px;
			text-align: center;
			line-height: 24px;
			color: $background-primary-color;
			background: $background-primary;
			border-radius: 2px;
			cursor: pointer;
		}
	}

	.banner {
		position: relative;

		.gallery {
			width: 100%;
			height: 100%;
			overflow: hidden;

			.gallery-cell {
				width: 100%;
				height: 100%;

				picture {
					position: relative;
					display: block;
					width: 100%;
					height: 100%;

					img {
						position: absolute;
						bottom: 0px;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: 50% 50%;
					}
				}
			}
		}
	}

	#header {
		margin-bottom: 3rem;

		.banner {
			width: 100%;
			max-width: 1920px;
			height: 128px;
			margin: 0px auto 3rem auto;

			.gallery {
				.gallery-cell {
					picture {
						img {
							object-position: 0% 100%;
						}
					}
				}

				.gallery-fullscreen-button {
					display: none;
				}
			}
		}

		> .row {
			margin: 0px auto;
			width: 85%;
			min-width: 1469px;
			max-width: 1600px;
		}

		#locales {
			position: relative;
			z-index: 3;

			.country-flag img {
				width: 25px;
				height: 25px;
				border-radius: 50%;
			}

			#locales-dropdown-wrap {
				position: absolute;
				top: 0px;
				right: 0px;
			}

			#locales-dropdown-toggle {
				margin-left: 0.75rem;
				border: none;
				font-size: 1.3rem;

				.country-flag img {
					margin-bottom: 1px;
				}
			}

			#locales-dropdown {
				margin: 0px 0px 0px -1px;
				padding: 0px;
				border-radius: 0px;

				.dropdown-item {
					font-size: 1.3rem;
				}

				.dropdown-item.active, .dropdown-item:hover {
					color: $background-secondary-color;
					background-color: $background-secondary;
				}
			}
		}

		#logo {
			position: relative;
			margin: 0px auto;
			padding: 0px 1.5rem 3rem 1.5rem;
			width: 85%;
			min-width: 1469px;
			max-width: 1600px;
			text-align: center;

			a {
				display: inline-block;
			}
		}

		#navigation {
			margin: 0px auto;
			padding-top: 0px;
			padding-bottom: 0px;
			width: 85%;
			min-width: 1469px;
			max-width: 1600px;
			background-color: $background-secondary;

			#navigation-logo {
				display: none;
			}

			#navigation-items {
				transition: none;
				justify-content: center;

				.nav-item {
					color: $background-secondary-color;

					.nav-link {
						padding: 1rem 1.5rem;
						font-size: 1.3rem;
						letter-spacing: 0.05rem;
						color: $background-secondary-color;
					}

					.nav-link:visited {
						color: $background-secondary-color;
					}

					.dropdown-menu {
						margin: 0px 0px 0px -1px;
						padding: 0px;
						border-radius: 0px;

						.dropdown-item {
							padding: 0.75rem 1.5rem;
							background-color: $background-secondary;
						}

						.dropdown-item, .dropdown-item:visited {
							color: $background-secondary-color;
						}

						.dropdown-item.active, .dropdown-item:active {
							color: $background-secondary-color;
							background-color: $background-secondary;
						}

						.dropdown-item:focus, .dropdown-item:hover {
							color: $background-secondary-color;
							background-color: $background-secondary;
						}
					}
				}

				.nav-item:focus, .nav-item:hover {
					background: $background-secondary;

					.nav-link {
						color: $background-secondary-color;
					}
				}
			}
		}
	}

	#content {
		h1 {
			font-size: 2.25rem;
		}

		h2 {
			font-size: 2.25rem;
		}
	}

	#footer {
		position: absolute;
		bottom: 0px;
		left: 50%;
		transform: translate(-50%, 0%);
		width: 85%;
		min-width: 1469px;
		max-width: 1600px;
		margin-top: 3rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		text-align: center;
		color: $background-secondary-color;
		background-color: $background-secondary;

		.footer-copyright {
			width: 100%;
		}
	}
}

#body.index {
	#header {
		margin-bottom: 0px;

		#navigation {
			border-bottom: none;
		}
	}

	#content {
		padding-top: 0px;

		.banner {
			margin: 0px auto 3rem auto;
			height: calc(100vh - 400px);
			min-height: 544px;
			max-height: 960px;
			width: 85%;
			min-width: 1469px;
			max-width: 1600px;

			.banner-guide {
				position: absolute;
				top: 0px;
				left: 50%;
				z-index: 2;
				transform: translate(-50%, 0%);
				height: 100%;

				.banner-overlay {
					position: absolute;
					display: flex;
					top: 0px;
					bottom: 0px;
					left: -1.5rem;
					z-index: 2;
					max-width: 456px;
					padding: 1.5rem;
					flex-direction: column;
					align-items: center;
					justify-content: space-evenly;
					background-color: rgba(255, 255, 255, 0.85);
					border-left: 1px solid rgba(255, 255, 255, 0.18);
					border-right: 1px solid rgba(255, 255, 255, 0.18);
					backdrop-filter: blur(4px);

					h1 {
						position: relative;
						margin: 0px;
						padding: 0px;
						font-family: EB Garamond;
						font-size: 3.6rem;
						line-height: 4.2rem;
						color: $secondary;
						text-align: center;

						i {
							position: absolute;
							top: 0px;
							left: 0px;
							display: none;
							font-size: 3rem;
							color: $highlight;
							text-align: left;
						}

						.brand {
							font-size: 4.2rem;
							font-weight: 700;
						}
					}

					h1:before {
						content: '';
						position: absolute;
						display: none;
						bottom: -6px;
						left: 50%;
						width: 80px;
						height: 3px;
						background: $highlight;
						transform: translate(-50%, 0%);
					}

					p {
						margin: 0px;
						padding: 0px;
						font-size: 1.25rem;
						font-weight: bold;
						text-align: center;

						a {
							text-decoration: underline;
						}
					}
				}
			}

			.gallery {
				.gallery-fullscreen-button {
					display: none;
				}
			}
		}

		> .container {
			> h2, > p {
				margin-bottom: 1.5rem;
			}

			> ul {
				margin-bottom: 1.5rem;

				li {
					margin-bottom: 0.75rem;
				}
			}

			> p + h2, > ul + h2 {
				margin-top: 3rem;
			}

			.certifications-wrap {
				picture {
					img {
						max-width: 100%;
					}
				}
			}
		}
	}
}

#body.activity, #body.pandemic {
	#content {
		h1 {
			margin-bottom: 1.5rem;
		}

		#activity-content, #pandemic-content {
			p {
				margin-bottom: 1.5rem;
			}

			p:last-child {
				margin-bottom: 3rem;
			}

			> div {
				padding-right: 1.5rem;
			}

			> div:last-child {
				padding-right: 0px;
				text-align: right;
			}

			.gallery {
				text-align: center;

				.gallery-cell {
					display: inline-block;

					img {
						max-width: 100%;
					}
				}
			}
		}
	}
}

#body.products, #body.suppliers {
	#content {
		h1, h1 + p {
			margin-bottom: 1.5rem;
		}

		.gallery {
			display: flex;
			flex-wrap: wrap;

			.gallery-cell {
				flex: 0 0 auto;
				width: 20%;
				display: inline-block;
				cursor: pointer;
				text-align: center;

				picture {
					display: inline-block;
					margin-bottom: 1.5rem;
					margin-right: 1.5rem;

					img {
						margin-right: 1.5rem;
					}
				}
			}

			.gallery-cell:hover {
				img {
					opacity: 0.8;
				}
			}

			.gallery-cell:nth-last-child(4n) {
				margin-right: 0px;
			}

			.gallery-fullscreen-button {
				top: 0px;
				left: 0px;
				right: auto;
				z-index: 2;
				width: 100%;
				height: 100%;
				background: none;
			}

			.gallery-fullscreen-button:before {
				content: '';
			}
		}
	}
}

#body.suppliers {
	#content {
		.gallery {
			.gallery-cell {
				picture {
					border: 1px solid $secondary;
				}
			}
		}
	}
}

#body.customers {
	#content {
		h1, h1 + p {
			margin-bottom: 1.5rem;
		}

		.geocharts-wrap {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			#geocharts-greece, #geocharts-world {
				width: 48%;
				height: auto;
				display: inline-block;
			}
		}
	}
}

#body.gallery {
	#content {
		h1 {
			margin-bottom: 1.5rem;
		}

		.gallery.bricklayer {
			.bricklayer-column-sizer {
				position: absolute;
				top: -9999px;
				left: -9999px;
				display: block;
				width: 352px;
				height: 0px;
				visibility: hidden;
			}

			.bricklayer-column {
				padding: 0px;
				padding-right: 0.75rem;

				.gallery-cell {
					margin-bottom: 0.75rem;
					cursor: pointer;
				}

				.gallery-cell:hover {
					img {
						opacity: 0.8;
					}
				}
			}

			.bricklayer-column:last-child {
				padding-right: 0px;
			}

			.gallery-fullscreen-button {
				top: 0px;
				left: 0px;
				right: auto;
				z-index: 2;
				width: 100%;
				height: 100%;
				background: none;
			}

			.gallery-fullscreen-button:before {
				content: '';
			}
		}
	}
}

#body.contact {
	#header {
		margin-bottom: 1.5rem;
	}

	#content {
		h1, h1 + p {
			margin-bottom: 1.5rem;
		}

		h2 {
			margin-bottom: 1.5rem;
			font-size: 1.75rem;
		}

		#contact-details {
			margin: 0px auto 3rem auto;
			padding: 1.5rem 0px;
			text-align: center;
			border: 1px solid $secondary;

			.row {
				> div {
					p:last-child {
						margin-bottom: 0px;
					}
				}
			}

			i {
				width: 68px;
				height: 68px;
				margin-bottom: 1.5rem;
				padding: 10px;
				border-radius: 50%;
				color: $highlight;
				font-size: 3rem;
			}

			i.fa-envelope, i.fa-phone-alt {
				padding: 14px 10px 6px 12px;
				font-size: 2.5rem;
			}
		}

		#contact-form {
			padding-right: 0.75rem;

			.row {
				margin-bottom: 1.5rem;
			}

			.row:nth-last-child(2) {
				margin-bottom: 0.75rem;
			}

			.row:last-child {
				margin-bottom: 0px;
			}

			#privacy-statement {
				margin: 0px;
				text-align: justify;
			}
		}

		#contact-googlemaps {
			width: 100%;
			height: 100%;
			padding-left: 0.75rem;
			border: 0
		}
	}
}

@include media-breakpoint-up(lg) {
#body {
	#header {
		#navigation {
			#navigation-items {
				.nav-item {
					.dropdown-menu {
						display: none;
					}
				}

				.nav-item:hover .dropdown-menu {
					display: block;
				}
			}
		}
	}
}
}

@include media-breakpoint-down(xxxl) {
#body {
	#header {
		> .row {
			width: auto;
			min-width: auto;
			max-width: 1320px;
		}

		#logo {
			width: auto;
			min-width: auto;
			max-width: none;
		}

		#navigation {
			width: 100%;
			min-width: auto;
			max-width: none;
		}
	}

	#footer {
		width: 100%;
		min-width: auto;
		max-width: none;
	}
}

#body.index {
	#content {
		.banner {
			width: auto;
			min-width: auto;
			max-width: none;
		}
	}
}
}

@include media-breakpoint-down(xxl) {
#body {
	#header {
		> .row {
			max-width: 1140px;
		}
	}
}

#body.products, #body.suppliers {
	#content {
		.gallery {
			.gallery-cell {
				width: 25%;
			}
		}
	}
}
}

@include media-breakpoint-down(xl) {
#body {
	#header {
		> .row {
			max-width: 960px;
		}
	}
}

#body.index {
	#content {
		.certifications-wrap {
			text-align: center;
		}
	}
}

#body.activity, #body.pandemic {
	#content {
		#activity-content, #pandemic-content {
			> div {
				padding-right: 0px;
			}
		}
	}
}

#body.customers {
	#content {
		.geocharts-wrap {
			flex-direction: column;
			justify-content: left;

			#geocharts-greece, #geocharts-world {
				width: auto;
				display: block;
			}

			#geocharts-greece {
				margin-bottom: 1.5rem;
			}
		}
	}
}

#body.contact {
	#content {
		#contact-details {
			margin-bottom: 1.5rem;
		}

		#contact-form {
			margin-bottom: 1.5rem;
			padding-right: 0px;

			#privacy-statement {
				text-align: left;
			}
		}

		#contact-googlemaps {
			padding-left: 0px;
			height: 400px;
		}
	}

	#footer {
		margin-top: 1.5rem;
	}
}
}

@include media-breakpoint-down(lg) {
#body {
	#header {
		> .row {
			max-width: 720px;
		}

		#locales {
			#locales-dropdown-wrap {
				top: 170px;
				padding-left: 1rem;

				#locales-dropdown-toggle {
					padding-right: 0px;
				}
			}
		}

		#navigation {
			border-top: 1px solid $secondary;
			border-bottom: 1px solid $secondary;
			background-color: $background-primary;

			#navigation-items {
				.nav-item {
					color: $background-primary-color;

					.nav-link {
						color: $background-primary-color;
						font-size: 1.5rem;
						text-align: left;
					}

					.nav-link:visited {
						color: $background-primary-color;
					}

					.dropdown-menu {
						margin-left: 1.5rem;
						border: none;

						.dropdown-item {
							text-align: left;
							background-color: $background-primary;
						}

						.dropdown-item, .dropdown-item:visited {
							color: $background-primary-color;
						}

						.dropdown-item.active, .dropdown-item:active {
							color: $background-primary-color;
							background-color: $background-primary;
						}

						.dropdown-item:focus, .dropdown-item:hover {
							color: $background-primary-color;
							background-color: $background-primary;
						}
					}
				}

				.nav-item:focus, .nav-item:hover {
					background: $background-primary;

					.nav-link {
						color: $background-primary-color;
					}
				}
			}

			#navigation-toggle {
				margin: 1rem 0px;
				padding-left: 0px;
				border: none;

				.navbar-toggle-expand {
					display: none;
					margin-right: 3px;
					vertical-align: middle;
				}

				.navbar-toggle-collapse {
					display: inline-block;
					font-weight: bold;
					vertical-align: middle;
				}

				.navbar-toggle-text {
					font-size: 1.3rem;
					vertical-align: middle;
				}
			}

			#navigation-toggle.collapsed {
				.navbar-toggle-expand {
					display: inline-block;
				}

				.navbar-toggle-collapse {
					display: none;
				}
			}
		}
	}

	#header.expand {
		#navigation {
			position: fixed;
			top: 0px;
			left: 0px;
			z-index: 100;
			width: 100vw;
			height: 100vh;
			border-top: none;
			border-bottom: none;
			overflow-y: scroll;
			background-color: $background-primary;

			#navigation-toggle {
				position: fixed;
				top: 0px;
				right: 0px;
				width: 5rem;
				height: 100vh;
				margin: 0px;
				padding: 0px;
				color: $background-secondary-color;
				background-color: $background-secondary;
				border-radius: initial;

				.navbar-toggle-text {
					display: none;
				}
			}

			#navigation-items {
				padding: 3rem 0px;
				align-items: flex-start;

				.nav-item {
					.dropdown-menu {
						border-left: 1px solid $highlight;
					}
				}
			}
		}
	}
}

#body.products, #body.suppliers {
	#content {
		.gallery {
			.gallery-cell {
				width: 33.3333333333%;
			}
		}
	}
}

#body.contact {
	#content {
		#contact-details {
			.row {
				> div {
					margin-bottom: 1.5rem;
				}

				> div:last-child {
					margin-bottom: 0px;
				}
			}

			i {
				margin-bottom: 0px;
			}
		}
	}
}
}

@include media-breakpoint-down(md) {
#body {
	#header {
		> .row {
			max-width: 540px;
		}
	}
}

#body.index {
	#content {
		.banner {
			.banner-guide {
				.banner-overlay {
					top: 50%;
					bottom: auto;
					left: 50%;
					transform: translate(-50%, -50%);
					width: auto;
					min-width: auto;

					h1 {
						margin-bottom: 1.5rem;
					}
				}
			}
		}
	}
}

#body.products, #body.suppliers {
	#content {
		.gallery {
			.gallery-cell {
				width: 50%;
			}

			.gallery-cell:nth-child(even) {
				picture {
					margin-left: 1.5rem;
					margin-right: 0px;
				}
			}
		}
	}
}
}

@include media-breakpoint-down(sm) {
#body {
	#header {
		> .row {
			max-width: none;
		}

		#locales {
			#locales-dropdown-wrap {
				top: 120px;
				padding-right: 1.5rem;
			}
		}

		#logo {
			a {
				position: relative;
				height: 63px;
				display: block;

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 320px;
					max-width: 100%;
				}
			}
		}

		#navigation {
			#navigation-toggle {
				padding-left: 1.5rem;
			}
		}
	}

	#header.expand {
		#navigation {
			padding-left: 0.75rem;

			#navigation-toggle {
				width: 3rem;
			}
		}
	}

	#content {
		.banner {
			margin: 0px auto 3rem auto;
			height: calc(100vh - 350px);
		}

		h1 {
			font-size: 2rem;
		}

		h2 {
			font-size: 2rem;
		}

		> .container {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
	}
}

#body.index {
	#header {
		#navigation {
			border-bottom: 1px solid $secondary;
		}
	}

	#content {
		.banner {
			height: auto;
			min-height: auto;
			max-height: none;
			margin: 1.5rem;

			.banner-guide {
				position: relative;
				top: auto;
				left: auto;
				transform: none;
				width: 100%;
				height: auto;
				background-color: rgba(40, 27, 108, 0.35);
				border: 1px solid rgba(255, 255, 255, 0.18);
				border-radius: 10px;
				backdrop-filter: blur(4px);

				.banner-overlay {
					position:relative;
					top: auto;
					left: auto;
					transform: none;
					max-width: none;
					padding: 1.5rem 0.75rem;
					background: none;
					border: none;
					backdrop-filter: none;

					h1 {
						color: $background-secondary-color;
					}

					p {
						font-size: 1.125rem;
						color: $background-secondary-color;

						a {
							color: $background-secondary-color;
						}
					}
				}
			}

			.gallery {
				position: absolute;
				top: 0px;
				left: 0px;
				z-index: 1;
				border-radius: 10px;
			}
		}
	}
}

#body.contact {
	#content {
		#contact-details {
			margin: 0px 1.5rem 1.5rem 1.5rem;
			width: auto;
		}
	}
}

#body.gallery {
	#content {
		.container {
			max-width: 100vw;
		}
	}
}
}

@media (max-width: 480px) {
#body {
	#header.expand {
		#navigation {
			#navigation-toggle {
				top: auto;
				bottom: 0px;
				left: 0px;
				right: auto;
				width: 100vw;
				height: 3rem;
			}
		}
	}
}

#body.index {
	#content {
		.banner {
			.banner-guide {
				.banner-overlay {
					h1 {
						font-size: 2.8rem;
						line-height: 3.4rem;

						.brand {
							font-size: 3.4rem;
						}
					}
				}
			}
		}
	}
}
}

@media (max-width: 448px) {
#body {
	p > .text-nowrap {
		white-space: normal !important;
	}
}

#body.products, #body.suppliers {
	#content {
		.gallery {
			.gallery-cell {
				width: 100%;
				margin-bottom: 1.5rem;

				picture {
					margin-right: 0px;
					margin-bottom: 0px;
				}
			}

			.gallery-cell:nth-child(even) {
				picture {
					margin-left: 0px;
				}
			}
		}
	}
}
}

@media (max-width: 404px) {
#body {
	.g-recaptcha {
		> div {
			width: auto !important;
			height: 58px !important;

			> div {
				transform: scale(0.62, 0.72);
				transform-origin: 0 0;
			}
		}
	}
}
}

@media (max-width: 352px) {
#body.index {
	#content {
		.banner {
			.banner-guide {
				.banner-overlay {
					h1 {
						font-size: 2rem;
						line-height: 2.6rem;

						.brand {
							font-size: 2.6rem;
						}
					}
				}
			}
		}
	}
}
}
